import React from "react";
import { Link } from "react-router-dom";
import "./index.css";
import SectionHeader from "../Components/SectionHeader";

function Mwc() {
    return (
        <div>
            <SectionHeader text="MWC BARCELONA 2025"></SectionHeader>
            <div className="section">
                <div className="mwc-section">
                    <div className="mwc-container" style={{alignItems: "center"}}>
                        <Link to="https://www.mwcbarcelona.com/exhibitors/30621-northeastern-university-wiot/"
                        target="_blank" rel="noopener noreferrer"
                        style={{textDecoration: "none", color: "black"}}>
                            <img src="/images/mwc2025.png" className="img-fluid mwc-image" alt="MWC"/>
                        </Link>
                        <p>
                            Visit us at MWC 2025, Barcelona, Spain<br/>
                            <a href="https://www.mwcbarcelona.com/exhibitors/30621-northeastern-university-wiot/"
                            target="_blank" rel="noopener noreferrer"
                            style={{textDecoration: "underlined", color: "black"}}>Hall 6 Stand 6D1</a><br/>
                            See firsthand Open6G demos on the nexus of AI and programmable Open RAN
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Mwc;