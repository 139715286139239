import { Link } from "react-router-dom";
import Card from "../Components/Card";
import Button from "../Components/Button";
import SectionHeader from "../Components/SectionHeader";
import "./index.css";

function News() {
    return (
        <div>
            <SectionHeader text="LATEST NEWS & UPDATES"></SectionHeader>
            <div className="section" style={{ alignItems: "center" }}>
                <div className="news-container">
                    <div className="lg-news">
                        <Link to="https://wiot.northeastern.edu/news/northeastern-university-open6-g-named-an-ai-ran-endorsed-laboratory/"
                        target="_blank" rel="noopener noreferrer"
                        style={{ textDecoration: 'none', color: 'inherit' }}>
                            <Card>
                                <img src="/images/ai-ran-lab.png" className="n-lg-img img-fluid" alt="AI-RAN lab"/>
                                <div className="card-text">
                                    <h5>Northeastern University Open6G Named an AI-RAN Alliance-Endorsed Lab</h5>
                                    <p>The AI-RAN Alliance has announced that Northeastern University's Open6G experimental platforms are now part of the first wireless laboratories endorsed by the organization. These state-of-the-art platforms offer a unique range of solutions for AI-RAN research, development, and testing—from large-scale hardware-in-the-loop emulation and over-the-air software-defined radios to continuous testing of RAN workloads and a private 5G network built on open and programmable software.</p>
                                    <p>02/27/2025</p>
                                </div>
                            </Card>
                        </Link>
                    </div>
                    <div className="news-section">
                        <div>
                            <div className="news-header">
                                <h3 className="dark-red">OTIC NEWS</h3>
                                <Link to="https://wiot.northeastern.edu/news/" target="_blank" rel="noopener noreferrer">
                                    <Button buttonType="button-primary" textStyle="subtitle">SEE ALL</Button>
                                </Link>
                            </div>
                            <div className="custom-row" style={{paddingBottom: "3rem"}}>
                                <div className="news-section">
                                    <Link to="https://wiot.northeastern.edu/news/otic-interoperability-testing-amplitech-mimo-radio/"
                                    target="_blank" rel="noopener noreferrer"
                                    style={{ textDecoration: 'none', color: 'inherit' }}>
                                        <Card>
                                            <img src="/images/person-wires.png" className="n-sm-img img-fluid" alt="person in colosseum"/>
                                            <div className="card-text">
                                                <h6>Open6G OTIC at Northeastern University to Perform O-RAN 5G Interoperability Testing and End-to-End Demonstration of AmpliTech’s O-RAN CAT B 64T64R MIMO Radio</h6>
                                                <p>05/14/2024</p>
                                            </div>
                                        </Card>
                                    </Link>
                                </div>
                                <div className="news-section">
                                    <Link to="https://wiot.northeastern.edu/news/open-6g-otic-general-availability-open-ran-testing-and-integration-solutions/"
                                    target="_blank" rel="noopener noreferrer"
                                    style={{ textDecoration: 'none', color: 'inherit' }}>
                                        <Card>
                                            <img src="/images/equipment.png" className="n-sm-img img-fluid" alt="equipment"/>
                                            <div className="card-text">
                                                <h6>The Open6G OTIC at Northeastern Announces General Availability of Open RAN Testing and Integration Solutions</h6>
                                                <p>04/03/2024</p>
                                            </div>
                                        </Card>
                                    </Link>
                                </div>
                                {/*<div className="news-section">
                                    <Link to="https://wiot.northeastern.edu/news/joint-north-american-o-ran-plugfest-readout-spring-2024/"
                                    target="_blank" rel="noopener noreferrer"
                                    style={{ textDecoration: 'none', color: 'inherit' }}>
                                        <Card>
                                            <img src="/images/plugfest.png" className="n-sm-img img-fluid" alt="plugfest"/>
                                            <div className="card-text">
                                                <h6>Joint North American O-RAN PlugFest Readout - Spring 2024</h6>
                                                <p>05/29/2024</p>
                                            </div>
                                        </Card>
                                    </Link>
                                </div>*/}
                            </div>
                        </div>
                        <div>
                            <div className="news-header">
                                <h3 className="dark-red">WIoT NEWS</h3>
                                <Link to="https://wiot.northeastern.edu/news/" target="_blank" rel="noopener noreferrer">
                                    <Button buttonType="button-primary" textStyle="subtitle">SEE ALL</Button>
                                </Link>
                            </div>
                            <div className="custom-row">
                                <div className="news-section">
                                    <Link to="https://wiot.northeastern.edu/education/5g-certification/"
                                    target="_blank" rel="noopener noreferrer"
                                    style={{ textDecoration: 'none', color: 'inherit' }}>
                                        <Card>
                                            <img src="/images/presentation.png" className="n-sm-img img-fluid" alt="presentation"/>
                                            <div className="card-text">
                                                <h6>WIoT Institute Presents 5G Training and Certification with Qualcomm Wireless Academy 5G University Program</h6>
                                                <p>09/27/2024</p>
                                            </div>
                                        </Card>
                                    </Link>
                                </div>
                                <div className="news-section">
                                    <Link to="https://wiot.northeastern.edu/news/wiot-students-showcase-research-at-rise-event/"
                                    target="_blank" rel="noopener noreferrer"
                                    style={{ textDecoration: 'none', color: 'inherit', width: "100%"}}>
                                        <Card>
                                            <img src="/images/rise.png" className="n-sm-img img-fluid" alt="rise"/>
                                            <div className="card-text">
                                                <h6>WIoT Students Showcase Research at RISE Event</h6>
                                                <p>04/11/2024</p>
                                            </div>
                                        </Card>
                                    </Link>
                                </div>
                            </div> 
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default News;