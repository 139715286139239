import React, { useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import Mwc from "./Mwc";
import FocusAreas from "./Areas";
import Partners from "./Partners";
import Values from "./Values";
import KeyComponents from "./KeyComponents";
import Locations from "./Locations";
import News from "./News";
import RequestBooking from "../Components/RequestBooking";
import "./index.css";

function Home() {
    const { hash } = useLocation();

    window.scrollTo(0, 0);

    useEffect(() => {      
        if (hash) {
            const element = document.querySelector(hash);
            if (element) {
                element.scrollIntoView({ behavior: 'smooth' });
            }
        }
    }, [hash]);

    return (
        <div style={{paddingTop: "3.612rem"}}>
            <div className="about-container">
                <div className="left-container">
                    <img src="/images/colosseum-centered.jpg" className="img-fluid no-border-radius" id="col-pic" alt="colosseum" />
                    <div className="about-overlay">
                        <div className="special-header">
                            <h1>OPEN6G AT&nbsp;</h1>
                            <h1>NORTHEASTERN</h1>
                        </div>
                        <h4>The One-Stop Shop for Open RAN Innovation</h4>
                        {/*<p>The North American OTIC in the Boston area (Open6G OTIC) builds on testbeds and facilities hosted in the Open6G space of the Institute for the Wireless Internet of Things (WloT) at Northeastern University, member of the O-RAN Alliance since 2020. The Open6G OTIC is a one-stop shop for Open RAN innovation. It serves as a hub for the development and testing of next-generation wireless networks utilizing Open RAN technology and Artificial Intelligence, offering a comprehensive range of testing services that facilitates compliance, interoperability, performance, and functional testing for Open RAN products.</p>*/}
                        <p>Open6G at Northeastern University is a hub for developing and testing next-generation wireless networks based on Open RAN. It builds on world-leading research, testbeds, and facilities hosted at the Institute for the Wireless Internet of Things (WloT) at Northeastern University. Open6G provides research and development of Open RAN solutions, testing and integration services as part of the Open6G OTIC, and system integration activities.</p>
                    </div>
                </div>
                <div className="right-container">
                    {/*<h2>OPEN6G: ONE-STOP SHOP FOR OPEN RAN INNOVATION</h2>*/}
                    <svg width="100%" height="0.1" viewBox="0 0 427 0.1" fill="#FFFFFF" xmlns="http://www.w3.org/2000/svg">
                        <line y1="0.05" x1="0" x2="100%" y2="0.05" stroke="#FFFFFF"/>
                    </svg>
                    <Link to="/Research" style={{textDecoration: "none", color: "inherit"}}>
                        <div className="page-section">
                            <div className="top-icon-row">
                                <img src="/images/icons/lightbulb.svg" alt="lightbulb" style={{maxHeight: "2.5rem", width: "auto"}}/>
                                <h4>Research & Development</h4>
                            </div>
                            <ul className="bullet">
                                <li><p className="subtitle" id="left">R&D in NextG Wireless, Open RAN, and 5G/6G Cellular Systems</p></li>
                                <li><p className="subtitle" id="left">Develop new proof-of-concepts and end-to-end demonstrations</p></li>
                                <li><p className="subtitle" id="left">Focus on real-world systems with large-scale testbed validation</p></li>
                            </ul>
                        </div>
                    </Link>
                    <Link to="/OTIC" style={{textDecoration: "none", color: "inherit"}}>
                        <div className="page-section">
                            <div className="top-icon-row">
                                <img src="images/icons/paper.svg" alt="paper" style={{maxHeight: "2.5rem", width: "auto"}}/>
                                <h4>Test & Integration (OTIC)</h4>
                            </div>
                            <ul className="bullet">
                                <li><p className="subtitle" id="left">Interoperability across multiple vendors</p></li>
                                <li><p className="subtitle" id="left">Certification, badging, and interoperability</p></li>
                                <li><p className="subtitle" id="left">Conformance, IOT, end-to-end testing (O-RAN ALLIANCE OTIC)</p></li>
                            </ul>
                        </div>
                    </Link>
                    <Link to="/Services" style={{textDecoration: "none", color: "inherit"}}>
                        <div className="page-section">
                            <div className="top-icon-row">
                                <img src="images/icons/wrench.svg" alt="wrench" style={{maxHeight: "2.5rem", width: "auto"}}/>
                                <h4>System Integration</h4>
                            </div>
                            <ul className="bullet">
                                <li><p className="subtitle" id="left">Optimization and operations</p></li>
                                <li><p className="subtitle" id="left">System design and digital twinning</p></li>
                                <li><p className="subtitle" id="left">End-to-end design and provisioning</p></li>
                            </ul>
                        </div>
                    </Link>
                    <svg width="100%" height="0.1" viewBox="0 0 427 0.1" fill="#FFFFFF" xmlns="http://www.w3.org/2000/svg">
                        <line y1="0.05" x1="0" x2="100%" y2="0.05" stroke="#FFFFFF"/>
                    </svg>
                </div>
            </div>
            <div style={{position: "relative"}}>
                <div id="MWc">
                    <Mwc/>
                </div>
                <div id="FocusAreas">
                    <FocusAreas/>
                </div>
                <div id="KeyComponents">
                    <KeyComponents/>
                </div>
                <div id="Partners">
                    <Partners/>
                </div>
                {/* <div id="Values">
                    <Values/>
                </div> */}
                <div id="Locations">
                    <Locations/>
                </div>
                <div id="News">
                    <News/>
                </div>
                <div id="RequestBooking">
                    <RequestBooking/>
                </div>
            </div>
        </div>
    );
}

export default Home;